@import url('https://fonts.googleapis.com/css2?family=Abel&family=EB+Garamond:wght@500&family=Quicksand:wght@300&display=swap');

* {
  font-family: Quicksand, serif;
}
#navBarOptions,
#marianaLozynska,
#h1TopHeader,
#cardTitle,
#footerContacts {
  font-family: Quicksand, serif;
}

#navBarSegment {
  margin: 0;
}

#logo {
  height: 90px;
  width: 90px;
}

#logoSmall {
  height: 60px;
  width: 60px;
  margin: -8px 0 -15px 100px;
}

#background {
  max-height: 225px;
}

.ui.menu {
  justify-content: center;
}

#logoInfo {
  display: flex;
  flex-direction: column;
  text-align: center;
}

#leftColumn {
  display: flex;
  align-items: center;
  justify-content: center;
}

#topHederSegment {
  max-height: 160px;
  margin: -10px -20px 0 0;
  font-family: Quicksand, serif !important;
}

h1.ui.header {
  margin: 20px 0 0 0;
  color: #9771bd;
}

#h1TopHeader {
  color: #9771bd;
  margin-bottom: 5px;
}

p {
  color: mediumturquoise;
  font-family: Quicksand, sans-serif !important;
  font-size: medium;
}

#segmentAboutMe {
  height: 35px;
  text-align: center;
  align-items: center;
  margin: -15px -15px 0 -21px;
  padding-top: 0;
}

.ui.header:first-child {
  margin-bottom: -5px;
}

#segmentAbout {
  padding: 40px 0 0 0;
}

#infoAbout {
  text-align: justify;
  color: black;
  font-size: large;
}

/*! Skills */
#skillsAnimation {
  animation-delay: 1s;
  max-width: 800px;
  display: inline-block;
}

#iconSegment {
  padding: 0;
}

#iconId {
  color: rebeccapurple;
  font-size: small;
  padding-top: 5px;
}

#iconCardContent {
  padding: 10px 0;
}

/*! Projects */

#cardContent {
  text-align: justify;
  font-size: small;
  min-height: 135px;
}

#projectCard {
  width: 250px;
  margin: 15px;
}

#cardP {
  color: rebeccapurple;
  font-size: small;
  text-align: center;
  font-weight: bold;
}

#cardTitle {
  min-height: 45px;
  color: rebeccapurple;
  text-align: center;
  font-size: large;
}

#unLi {
  min-height: 75px;
  text-align: center;
}

#list {
  font-weight: lighter;
  font-size: small;
  margin-top: 0px;
}

#cardFooter {
  text-align: center;
  background: rgb(240 227 246);
}

/*! Footer */
#footerSegment {
  margin: 20px -20px 0 -20px;
  padding: 0 0 75px 0;
  display: flex;
  justify-content: center;
  max-height: 50px;
}

/*! Interests */
.interests {
  text-align: center;
  margin-top: 20px !important;
  font-family: Quicksand, serif !important;
}

.interestsType {
  text-align: center;
  font-family: Quicksand, serif !important;
}

.pInterests {
  text-align: end;
  padding: 20px 50px 0 50px;
  font-size: large;
}

.pInterestsDescription {
  text-align: justify;
  padding: 0 50px;
  font-size: large;
}

#carouselImg {
  height: 380px;
  width: 275px;
  padding-bottom: 50px;
}

/*! Contact*/
#contactInfo {
  display: flex;
  justify-content: center;
  padding: 8px 0 0 0;
}
